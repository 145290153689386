import React from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.svg";
import { ImPhone } from "react-icons/im";

export default () => (
  <Container>
    <div className="container">
      <a className="logo" href="/">
        <img src={Logo} className="site-logo" alt="logo" />
      </a>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <a className="contact" href="tel:99099547" style={{ marginRight: 10 }}>
          <ImPhone /> 9589-1116
        </a>
        <a className="contact" href="tel:99099547">
          <ImPhone /> 9589-4774
        </a>
      </div>
    </div>
  </Container>
);

const Container = styled.div`
  position: relative;
  z-index: 10;
  border-bottom: 1px solid #ddd;
  background: #fff;
  .logo {
    display: flex;
    img {
      width: 200px;
    }
  }
  .contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    border: 2px solid #ddd;
    padding: 4px 8px;
    border-radius: 8px;
    color: #333;
    text-decoration: none;
    transition: border 0.2s linear;
    &:hover {
      border-color: #169fe8;
    }
    svg {
      margin-right: 10px;
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;
