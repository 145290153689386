import request from "./request";
import moment from "moment";
import numeral from "numeral";

const dateFormat = (date) => moment(date).format("YYYY-MM-DD");
const datetimeFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm");

// numeral.register("locale", "jp", {
//   delimiters: {
//     thousands: ",",
//     decimal  : ","
//   },
//   abbreviations: {
//     thousand: "k",
//     million : "m",
//     billion : "b",
//     trillion: "t"
//   },
//   currency: {
//     symbol: "₮"
//   }
// });
// numeral.locale("jp");


const tugrug = val => numeral(val).format("0,0₮");

export { tugrug, request, dateFormat, datetimeFormat };
