import React from "react";
import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import styled from "styled-components";

const App = () => {

  return (
    <Container>
      <Header />
      <Home />
      <Footer />
    </Container>
  );
};
const Container = styled.div` 
  display: block;
  position: relative;
  padding-bottom: 20px;
`;
export default App;
