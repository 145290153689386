import React from "react";
import styled from "styled-components";
import Logo from "../../assets/logo.svg";
import { ImPhone } from "react-icons/im";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { BsFacebook, BsTwitter, BsInstagram, BsYoutube } from "react-icons/bs";

export default () => (
  <Container className="container">
    <div className="section">
      <div className="row">
        <div className="col">
          <a className="logo" href="/"><img src={Logo} className="site-logo" alt="logo" /></a>
          <p>Та өөрийн авто машины чанартай сэлбэгийг биднээс аваарай.</p>
          <ul className="socials">
            <li><a href="https://www.facebook.com/TochkaOporiMongolia" target="_blank"><BsFacebook /></a></li>
            <li><a href=""><BsTwitter /></a></li>
            <li><a href=""><BsInstagram /></a></li>
            <li><a href=""><BsYoutube /></a></li>
          </ul>
        </div>
        <div className="col">
          <h4>Холбоо барих</h4>
          <ul className="contact">
            <li><a><div className="icon"><FaMapMarkerAlt /></div> Нисдэг машины баруун талд Ялалт плазагийн OEM Autoparts худалдааны төвийн 2 давхарт 2 тоот.</a></li>
            <li><a href="tel:9909-9547"><div className="icon"><ImPhone /></div> 9589-1116</a></li>
            <li><a href="mailto:manager@ono.mn"><div className="icon"><MdAlternateEmail /></div> manager@ono.mn</a></li>
          </ul>
        </div>
      </div>
    </div>
  </Container>
);

const Container = styled.div`
  position: relative;
  .section {
    background: #f4f6fa;
    padding: 24px 48px;
    border-radius: 10px;
    p {
      font-size: 15px;
      font-weight: 600;
      margin: 10px 0;
    }
    .socials {
      list-style: none;
      padding: 0;
      margin: 0 0 20px 0;
      li {
        display: inline;
        margin-right: 15px;
        a {
          font-size: 24px;
          color: #333;
        }
      }
    }
  }
  .logo {
    display: flex;
    img {
      width: 200px;
    }
  }
  h4 {
    font-size: 18px;
  }
  .contact {
   list-style: none;
   padding: 0;
   margin: 0;
   li {
    margin-bottom: 5px;
    margin-bottom: 10px;
    a {
      display: flex;
      font-size: 15px;
      color: #333;
      text-decoration: none;
      .icon {
        display: block;
        background: #fff;
        border-radius: 50%;
        min-width: 25px!important;
        height: 25px!important;
        text-align: center;
        margin-right: 10px;
      }
    }
   }
  }
`;
