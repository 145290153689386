import qs from "qs";
// import { store } from "../";
// import { message, notification } from "antd";

// const api_host = process.env.NODE_ENV === "production" ? process.env.API_HOST || "" : "";

const request = async (url, data, options, verbose) => {
  let defaultOptions = {
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify(data),
    ...options,
  };

  let queryString = "";

  if (options.method === "GET") {
    delete defaultOptions.body;

    queryString = `?${qs.stringify(data)}`;
  }

  const response = await fetch(`${url}${queryString}`, defaultOptions);

  // eslint-disable-next-line no-useless-catch
  try {
    const json = await response.json();

    if (response.status === 401) {
      if (verbose) {
        window.location.href = "/";
      }
      throw json;
    }

    if (response.status === 403) {
      if (json && json.message === "CUSTOMER_NOTFOUND") window.location.href = "/";

      throw json;
    }

    if (response.status >= 200 && response.status < 400) {
      return json;
    }

    throw json;
  } catch (err) {
    throw err;
  }
};

const httpMethod = (signal) => {
  return {
    get: (url, data, options, verbose = true) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "GET" }, verbose);
    },
    post: (url, data, options) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "POST" });
    },
    put: (url, data, options) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "PUT" });
    },
    del: (url, data, options) => {
      if (signal) options.signal = signal;

      return request(url, data, { ...options, method: "DELETE" });
    },
  };
};

export default {
  ...httpMethod(),
  signal: (signal) => httpMethod(signal),
};
